import { useEffect } from "react";
import { Outlet } from "react-router-dom";

// material-ui
import { useTheme } from "@mui/material/styles";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Toolbar from "@mui/material/Toolbar";
import useMediaQuery from "@mui/material/useMediaQuery";

// project imports
import Header from "./Header";
import Sidebar from "./Sidebar";
import MainContentStyled from "./MainContentStyled";
import Loader from "ui-component/Loader";
import Breadcrumbs from "ui-component/extended/Breadcrumbs";
import useConfig from "hooks/useConfig";
import useGetBreadcrumbs from "hooks/useGetBreadCrumbs";
import { handlerDrawerOpen, useGetMenuMaster } from "api/menu";

// types
import { MenuOrientation } from "types/config";

// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = () => {
  const theme = useTheme();
  const downMD = useMediaQuery(theme.breakpoints.down("md"));
  const { borderRadius, container, miniDrawer, menuOrientation } = useConfig();
  const { menuMaster, menuMasterLoading } = useGetMenuMaster();
  const currentBreadcrumb = useGetBreadcrumbs();
  const drawerOpen = menuMaster?.isDashboardDrawerOpened;

  useEffect(() => {
    handlerDrawerOpen(!miniDrawer);
  }, [miniDrawer]);

  useEffect(() => {
    downMD && handlerDrawerOpen(false);
  }, [downMD]);

  const isHorizontal =
    menuOrientation === MenuOrientation.HORIZONTAL && !downMD;

  if (menuMasterLoading) return <Loader />;

  return (
    <Box sx={{ display: "flex" }}>
      {/* header */}
      <AppBar
        enableColorOnDark
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{ bgcolor: "background.default" }}>
        <Toolbar sx={{ p: isHorizontal ? 1.25 : 2 }}>
          <Header />
        </Toolbar>
      </AppBar>

      {/* menu / drawer */}
      <Sidebar />

      {/* main content */}
      <MainContentStyled
        {...{ borderRadius, menuOrientation, open: drawerOpen, theme }}>
        <Container
          maxWidth={container ? "lg" : false}
          {...(!container && {
            sx: {
              px: { xs: 0 },
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
            },
          })}>
          {/* breadcrumb */}
          <Breadcrumbs
            custom
            heading={currentBreadcrumb.breadcrumbHeading}
            links={currentBreadcrumb.breadcrumbLinks}
          />
          <Outlet />
        </Container>
      </MainContentStyled>
      {/* <Customization /> */}
    </Box>
  );
};

export default MainLayout;
