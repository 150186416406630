import dashboard from "./dashboard";
import users from "./users";
import { NavItemType } from "types";

// ==============================|| MENU ITEMS ||============================== //

const menuItems: { items: NavItemType[] } = {
  items: [dashboard, users],
};

export default menuItems;
