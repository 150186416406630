// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports
import { dispatch } from "../index";

// types
import { DefaultRootStateProps } from "types";
import { UserProfile } from "types/user-profile";

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps["contact"] = {
  error: null,
  contacts: [],
};

const slice = createSlice({
  name: "contact",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET CONTACTS
    getContactsSuccess(state, action) {
      state.contacts = action.payload;
    },

    // MODIFY CONTACT
    modifyContactSuccess(state, action) {
      state.contacts = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getContacts() {
  return async () => {
    try {
      dispatch(slice.actions.getContactsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function modifyContact(contact: UserProfile) {
  return async () => {
    try {
      dispatch(slice.actions.modifyContactSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
