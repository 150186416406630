//material ui
import Badge, { BadgeProps } from "@mui/material/Badge";
import { PropsWithChildren } from "react";

//project imports
import { useGetSignatureListCurrentQuery } from "services/signatures";

type MenuItemBadgeProps = {
  id: string | undefined;
  badge: BadgeProps;
  drawerOpen: boolean;
};
// ==============================|| MENU ITEM BADGE ||============================== //
const MenuItemBadge = ({
  id,
  badge,
  drawerOpen,
  children,
}: PropsWithChildren<MenuItemBadgeProps>) => {
  const { data: signature } = useGetSignatureListCurrentQuery(
    {
      pageNumber: 0,
      pageSize: 0,
    },
    { skip: id !== "signatures" }
  );

  if (!signature?.totalElements || drawerOpen) return children;

  return (
    <Badge badgeContent={signature?.totalElements} color={badge.color}>
      {children}
    </Badge>
  );
};

export default MenuItemBadge;
