import { createApi } from "@reduxjs/toolkit/query/react";
import { baseAppQuery } from "./baseQuery";
import { Filter } from "types/filters";
import { DisbursementData } from "types/contracts";
import { UploadedFile } from "types/dropzone";
import { EDisbursementStatus } from "types/statuses";

const ENDPOINT = "disbursement";
const disbursementApi: any = createApi({
  reducerPath: "disbursementApi",
  baseQuery: baseAppQuery,
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  tagTypes: ["disbursement"],
  endpoints: (builder) => ({
    getDisbursementListByApplicationId: builder.query<
      DisbursementData,
      { applicationId: string; filter: Filter }
    >({
      query: ({ applicationId, filter }) => {
        const queryParams = new URLSearchParams();
        if (filter) {
          queryParams.set("page", filter.pageNumber.toString());
          queryParams.set("size", filter.pageSize.toString());
        }
        return `${ENDPOINT}/fetch-all-by-application?applicationId=${applicationId}&${queryParams}`;
      },
      providesTags: ["disbursement"],
    }),
    getDisbursementListByOrganizationId: builder.query<
      DisbursementData,
      { organizationId: string; filter: Filter }
    >({
      query: ({ organizationId, filter }) => {
        const queryParams = new URLSearchParams();
        if (filter) {
          queryParams.set("page", filter.pageNumber.toString());
          queryParams.set("size", filter.pageSize.toString());
        }
        return `${ENDPOINT}/fetch-all-by-organization?organizationId=${organizationId}&${queryParams}`;
      },
      providesTags: ["disbursement"],
    }),
    getDisbursementListByNoticeId: builder.query<
      DisbursementData,
      { noticeId: string; filter: Filter }
    >({
      query: ({ noticeId, filter }) => {
        const queryParams = new URLSearchParams();
        if (filter) {
          queryParams.set("page", filter.pageNumber.toString());
          queryParams.set("size", filter.pageSize.toString());
        }
        return `${ENDPOINT}/fetch-all-by-notice?noticeId=${noticeId}&${queryParams}`;
      },
      providesTags: ["disbursement"],
    }),
    getDisbursementListCurrentByNoticeId: builder.query<
      DisbursementData,
      { noticeId: string; filter: Filter }
    >({
      query: ({ noticeId, filter }) => {
        const queryParams = new URLSearchParams();
        if (filter) {
          queryParams.set("page", filter.pageNumber.toString());
          queryParams.set("size", filter.pageSize.toString());
        }
        return `${ENDPOINT}/fetch-all-current?noticeId=${noticeId}&${queryParams}`;
      },
      providesTags: ["disbursement"],
    }),
    getDisbursementAvailableLimit: builder.query<string, string>({
      query: (noticeId) => `${ENDPOINT}/available-limit?noticeId=${noticeId}`,
    }),
    downloadDisbursement: builder.query<UploadedFile, string>({
      query: (disbursementId) => `${ENDPOINT}/download/${disbursementId}`,
    }),
    createDisbursement: builder.mutation<
      DisbursementData,
      { noticeId: string; amount: number }
    >({
      query: ({ noticeId, amount }) => ({
        url: `${ENDPOINT}?amount=${amount}&noticeId=${noticeId}`,
        method: "POST",
      }),
      invalidatesTags: ["disbursement"],
    }),
    updateDisbursement: builder.mutation<
      DisbursementData,
      { disbursementId: string; status: EDisbursementStatus }
    >({
      query: ({ disbursementId, status }) => ({
        url: `${ENDPOINT}/update-status?disbursementId=${disbursementId}&status=${status}`,
        method: "PUT",
      }),
      invalidatesTags: ["disbursement"],
    }),
  }),
});

export const {
  useGetDisbursementListByApplicationIdQuery,
  useGetDisbursementListByOrganizationIdQuery,
  useGetDisbursementListByNoticeIdQuery,
  useGetDisbursementListCurrentByNoticeIdQuery,
  useGetDisbursementAvailableLimitQuery,
  useDownloadDisbursementQuery,
  useLazyDownloadDisbursementQuery,
  useCreateDisbursementMutation,
  useUpdateDisbursementMutation,
} = disbursementApi;
export default disbursementApi;
