// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports

import { dispatch } from "../index";

// types
import { DefaultRootStateProps } from "types";

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps["customer"] = {
  error: null,
  customers: [],
  orders: [],
  products: [],
  productreviews: [],
  invoices: [],
};

const slice = createSlice({
  name: "customer",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET CUSTOMERS
    getCustomersSuccess(state, action) {
      state.customers = action.payload;
    },

    // GET ORDERS
    getOrdersSuccess(state, action) {
      state.orders = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.products = action.payload;
    },

    // GET PRODUCT REVIEWS
    getProductReviewsSuccess(state, action) {
      state.productreviews = action.payload;
    },

    // GET INVOICE DATA
    getInvoiceSuccess(state, action) {
      state.invoices = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getCustomers() {
  return async () => {
    try {
      dispatch(slice.actions.getCustomersSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getOrders() {
  return async () => {
    try {
      dispatch(slice.actions.getOrdersSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getInvoice() {
  return async () => {
    try {
      dispatch(slice.actions.getInvoiceSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProducts() {
  return async () => {
    try {
      dispatch(slice.actions.getProductsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductReviews() {
  return async () => {
    try {
      dispatch(slice.actions.getProductReviewsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
