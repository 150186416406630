import { createApi } from "@reduxjs/toolkit/query/react";
import { ClientData, ClientFields, BankAccount, BankAccountFields } from "types/users";
import { Filter } from "types/filters";
import { baseAppQuery } from "./baseQuery";

const ENDPOINT = "/client";
const clientsApi: any = createApi({
  reducerPath: "clientsApi",
  baseQuery: baseAppQuery,
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  tagTypes: ["clients"],
  endpoints: (builder) => ({
    getRoleList: builder.query<string[], string[]>({
      query: () => `${ENDPOINT}/role/list`,
    }),
  }),
});

export const {
  useGetRoleListQuery,
} = clientsApi;
export default clientsApi;
