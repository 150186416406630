// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports

import { dispatch } from "../index";

// types
import { DefaultRootStateProps } from "types";
import { Reply } from "types/user-profile";

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps["user"] = {
  error: null,
  usersS1: [],
  usersS2: [],
  followers: [],
  friendRequests: [],
  friends: [],
  gallery: [],
  posts: [],
  detailCards: [],
  simpleCards: [],
  profileCards: [],
};

const slice = createSlice({
  name: "user",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET USERS STYLE 1
    getUsersListStyle1Success(state, action) {
      state.usersS1 = action.payload;
    },

    // GET USERS STYLE 2
    getUsersListStyle2Success(state, action) {
      state.usersS2 = action.payload;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.followers = action.payload;
    },

    // FILTER FOLLOWERS
    filterFollowersSuccess(state, action) {
      state.followers = action.payload;
    },

    // GET FRIEND REQUESTS
    getFriendRequestsSuccess(state, action) {
      state.friendRequests = action.payload;
    },

    // FILTER FRIEND REQUESTS
    filterFriendRequestsSuccess(state, action) {
      state.friendRequests = action.payload;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.friends = action.payload;
    },

    // FILTER FRIENDS
    filterFriendsSuccess(state, action) {
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.gallery = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.posts = action.payload;
    },

    // EDIT COMMENT
    editCommentSuccess(state, action) {
      state.posts = action.payload;
    },

    // ADD COMMENT
    addCommentSuccess(state, action) {
      state.posts = action.payload;
    },

    // ADD REPLY
    addReplySuccess(state, action) {
      state.posts = action.payload;
    },

    // LIKE POST
    likePostSuccess(state, action) {
      state.posts = action.payload;
    },

    // LIKE COMMENT
    likeCommentSuccess(state, action) {
      state.posts = action.payload;
    },

    // LIKE REPLY
    likeReplySuccess(state, action) {
      state.posts = action.payload;
    },

    // GET DETAIL CARDS
    getDetailCardsSuccess(state, action) {
      state.detailCards = action.payload;
    },

    // FILTER DETAIL CARDS
    filterDetailCardsSuccess(state, action) {
      state.detailCards = action.payload;
    },

    // GET SIMPLE CARDS
    getSimpleCardsSuccess(state, action) {
      state.simpleCards = action.payload;
    },

    // FILTER SIMPLE CARDS
    filterSimpleCardsSuccess(state, action) {
      state.simpleCards = action.payload;
    },

    // GET PROFILE CARDS
    getProfileCardsSuccess(state, action) {
      state.profileCards = action.payload;
    },

    // FILTER PROFILE CARDS
    filterProfileCardsSuccess(state, action) {
      state.profileCards = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getUsersListStyle1() {
  return async () => {
    try {
      dispatch(slice.actions.getUsersListStyle1Success([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUsersListStyle2() {
  return async () => {
    try {
      dispatch(slice.actions.getUsersListStyle2Success([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFollowers() {
  return async () => {
    try {
      dispatch(slice.actions.getFollowersSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterFollowers(key: string) {
  return async () => {
    try {
      dispatch(slice.actions.filterFollowersSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFriendRequests() {
  return async () => {
    try {
      dispatch(slice.actions.getFriendRequestsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterFriendRequests(key: string) {
  return async () => {
    try {
      dispatch(slice.actions.filterFriendRequestsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getFriends() {
  return async () => {
    try {
      dispatch(slice.actions.getFriendsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterFriends(key: string) {
  return async () => {
    try {
      dispatch(slice.actions.filterFriendsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getGallery() {
  return async () => {
    try {
      dispatch(slice.actions.getGallerySuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getPosts() {
  return async () => {
    try {
      dispatch(slice.actions.getPostsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editComment(key: string, id: string) {
  return async () => {
    try {
      dispatch(slice.actions.editCommentSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addComment(postId: string, comment: Reply) {
  return async () => {
    try {
      dispatch(slice.actions.addCommentSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addReply(postId: string, commentId: string, reply: Reply) {
  return async () => {
    try {
      dispatch(slice.actions.addReplySuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function likePost(postId: string) {
  return async () => {
    try {
      dispatch(slice.actions.likePostSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function likeComment(postId: string, commentId: string) {
  return async () => {
    try {
      dispatch(slice.actions.likeCommentSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function likeReply(postId: string, commentId: string, replayId: string) {
  return async () => {
    try {
      dispatch(slice.actions.likeReplySuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDetailCards() {
  return async () => {
    try {
      dispatch(slice.actions.getDetailCardsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterDetailCards(key: string) {
  return async () => {
    try {
      dispatch(slice.actions.filterDetailCardsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSimpleCards() {
  return async () => {
    try {
      dispatch(slice.actions.getSimpleCardsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterSimpleCards(key: string) {
  return async () => {
    try {
      dispatch(slice.actions.filterSimpleCardsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProfileCards() {
  return async () => {
    try {
      dispatch(slice.actions.getProfileCardsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function filterProfileCards(key: string) {
  return async () => {
    try {
      dispatch(slice.actions.filterProfileCardsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
