import { createApi } from "@reduxjs/toolkit/query/react";
import { DebtorData, DebtorFields, DebtorFilter } from "types/debtors";
import { baseAppQuery } from "./baseQuery";

const ENDPOINT = "/debtor";
const debtorsApi: any = createApi({
  reducerPath: "debtorsApi",
  baseQuery: baseAppQuery,
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  tagTypes: ["debtors"],
  endpoints: (builder) => ({
    getDebtorList: builder.query<DebtorData, DebtorFilter>({
      query: (filter) => {
        const queryParams = new URLSearchParams();
        if (filter) {
          queryParams.set("page", filter.pageNumber.toString());
          queryParams.set("size", filter.pageSize.toString());
          if (filter.bin) queryParams.set("bin", filter.bin);
          if (filter.debtorName) {
            queryParams.set("debtorName", filter.debtorName);
          }
          if (filter.isWhite === "inWhiteList") {
            queryParams.set("isWhite", "true");
          }
          if (filter.isWhite === "notInWhiteList") {
            queryParams.set("isWhite", "false");
          }
        }
        return `${ENDPOINT}/fetch-all?${queryParams}`;
      },
      providesTags: ["debtors"],
    }),
    getDebtorById: builder.query<DebtorData, string>({
      query: (id) => `${ENDPOINT}/${id}`,
      providesTags: ["debtors"],
    }),
    addDebtor: builder.mutation<DebtorData, DebtorFields>({
      query: (data) => ({
        url: ENDPOINT,
        method: "POST",
        body: data,
      }),
      invalidatesTags: ["debtors"],
    }),
    updateDebtor: builder.mutation<DebtorData, DebtorFields>({
      query: (data) => ({
        url: ENDPOINT,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["debtors"],
    }),
  }),
});

export const {
  useGetDebtorListQuery,
  useGetDebtorByIdQuery,
  useAddDebtorMutation,
  useUpdateDebtorMutation,
} = debtorsApi;
export default debtorsApi;
