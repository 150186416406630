//types
import { DecodedJwt } from "types/token";

//third-party
import { jwtDecode } from "jwt-decode";

export const getUserData = () => {
  const token = localStorage.getItem("accessToken");
  if (!token) return null;
  const user: DecodedJwt = jwtDecode(token);
  return user;
};
