// third-party
import { FormattedMessage } from "react-intl";

// assets
import {
  IconDashboard,
  IconFilePencil,
  IconFileCheck,
  IconFileLike,
} from "@tabler/icons-react";

// type
import { NavItemType } from "types";
import { EApplicationController } from "types/actions";

const icons = {
  IconDashboard: IconDashboard,
  IconFilePencil: IconFilePencil,
  IconFileCheck: IconFileCheck,
  IconFileLike: IconFileLike,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard: NavItemType = {
  id: "dashboard",
  title: <FormattedMessage id="dashboard" />,
  icon: icons.IconDashboard,
  type: "group",
  actions: [
    EApplicationController.fetch_all_applications,
    EApplicationController.fetch_all_applications_for_current_user,
  ],
  children: [
    {
      id: "applications",
      title: <FormattedMessage id="applications" />,
      type: "item",
      icon: icons.IconFilePencil,
      url: "/dashboard/applications",
      breadcrumbs: true,
      children: [
        {
          id: "create-application",
          title: <FormattedMessage id="create-application" />,
          type: "item",
          url: "/dashboard/applications/create-application/:id/:ordinalNumber",
          breadcrumbs: true,
          actions: [EApplicationController.create_application],
        },
        {
          id: "edit-application",
          title: <FormattedMessage id="edit-application" />,
          type: "item",
          url: "/dashboard/applications/edit-application/:id/:ordinalNumber",
          breadcrumbs: true,
          actions: [EApplicationController.create_application],
        },
        {
          id: "application-details",
          title: <FormattedMessage id="application-details" />,
          type: "item",
          url: "/dashboard/applications/application-details/:id/:ordinalNumber",
          breadcrumbs: true,
          actions: [
            EApplicationController.fetch_application,
            EApplicationController.fetch_application_status_history,
          ],
        },
      ],
    },
    {
      id: "contracts",
      title: <FormattedMessage id="contracts" />,
      type: "item",
      icon: icons.IconFileLike,
      url: "/dashboard/contracts",
      breadcrumbs: true,
      children: [
        {
          id: "contract-details",
          title: <FormattedMessage id="contract-details" />,
          type: "not-collapsible-item",
          url: "/dashboard/contracts/contract-details/:id/:ordinalNumber/:applicationId/",
          breadcrumbs: true,
        },
      ],
    },
    {
      id: "signatures",
      title: <FormattedMessage id="signatures" />,
      type: "item",
      icon: icons.IconFileCheck,
      url: "/dashboard/signatures",
      breadcrumbs: true,
      chip: { color: "primary" },
      children: [
        {
          id: "signature-details",
          title: <FormattedMessage id="signature-details" />,
          type: "item",
          url: "/dashboard/signatures/signature-details/:id/:applicationId/:signatureType?/:enclosureId?",
          breadcrumbs: true,
        },
      ],
    },
  ],
};

export default dashboard;
