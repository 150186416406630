import { createApi } from "@reduxjs/toolkit/query/react";
import { ReportDocument, CompanyInfo, CompanyInfoError } from "types/report";
import { baseAppQuery } from "./baseQuery";

const ENDPOINT = "/report";
const reportApi: any = createApi({
  reducerPath: "reportApi",
  baseQuery: baseAppQuery,
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  tagTypes: ["report"],
  endpoints: (builder) => ({
    getReportList: builder.query<ReportDocument[], string>({
      query: (applicationId) => `${ENDPOINT}/${applicationId}`,
      providesTags: ["report"],
    }),
    getCompanyInfo: builder.query<CompanyInfo|CompanyInfoError, string>({
      query: (iinBin) => `${ENDPOINT}/company-info/${iinBin}`,
      providesTags: ["report"],
    }),
    requestPkbReport: builder.mutation<
      undefined,
      { clientIinBin: string; applicationId: string }
    >({
      query: ({ clientIinBin, applicationId }) => ({
        url: `${ENDPOINT}/request-pkb/${clientIinBin}/${applicationId}`,
        method: "POST",
      }),
      invalidatesTags: ["report"],
    }),
    requestKompraReport: builder.mutation<
      undefined,
      { clientIinBin: string; applicationId: string }
    >({
      query: ({ clientIinBin, applicationId }) => ({
        url: `${ENDPOINT}/request-kompra/${clientIinBin}/${applicationId}`,
        method: "POST",
      }),
      invalidatesTags: ["report"],
    }),
  }),
});

export const {
  useGetReportListQuery,
  useLazyGetCompanyInfoQuery,
  useRequestPkbReportMutation,
  useRequestKompraReportMutation,
} = reportApi;
export default reportApi;
