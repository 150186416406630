// material-ui
import { useTheme } from "@mui/material/styles";

// types
import { ThemeMode } from "types/config";

type LogoProps = {
  color?: string;
  width?: string;
  height?: string;
};
// ==============================|| LOGO SVG ||============================== //

const Logo = ({ color, width = "63", height = "32" }: LogoProps) => {
  const theme = useTheme();
  const textColor = theme.palette.mode === ThemeMode.DARK ? "#FFFF" : "#1D1D1F";

  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 63 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.9778 3.33789C44.3628 3.33789 39 8.24908 39 14.3073C39 20.3655 44.3628 25.2769 50.9778 25.2769C53.8459 25.2769 56.4785 24.3536 58.5405 22.8141L60.7267 24.9695H63L59.7559 21.7708C61.7414 19.8132 62.9554 17.1901 62.9554 14.3073C62.9554 8.24908 57.5928 3.33789 50.9778 3.33789ZM40.6944 14.3073C40.6944 9.10593 45.2984 4.88966 50.9778 4.88966C56.6572 4.88966 61.2611 9.10593 61.2611 14.3073C61.2611 16.741 60.2535 18.959 58.5992 20.6303L54.137 16.2312H51.8637L57.3844 21.6745C55.6272 22.9581 53.4004 23.7252 50.9778 23.7252C45.2984 23.7252 40.6944 19.5086 40.6944 14.3073Z"
        fill="#FFD010"
      />
      <path
        d="M1.584 6.80176V22.5098H3.08V15.0298H10.604V13.7538H3.08V8.07777H11.55V6.80176H1.584ZM15.7104 6.80176V22.5098H17.2064V6.80176H15.7104ZM22.6554 6.80176V22.5098H24.1514V9.22176H24.1954L33.3474 22.5098H35.0194V6.80176H33.5234V20.0898H33.4794L24.3274 6.80176H22.6554Z"
        fill={color || textColor}
      />
    </svg>
  );
};

export default Logo;
