// third-party
import { configureStore } from "@reduxjs/toolkit";
import {
  useDispatch as useAppDispatch,
  useSelector as useAppSelector,
  TypedUseSelectorHook,
} from "react-redux";

import { persistStore } from "redux-persist";

// project imports
import rootReducer from "./reducer";
import authApi from "services/auth";
import clientsApi from "services/clients";
import employeesApi from "services/employees";
import debtorsApi from "services/debtors";
import applicationApi from "services/applications";
import dictionaryApi from "services/dictionary";
import signatureApi from "services/signatures";
import contractsApi from "services/contracts";
import enclosuresApi from "services/enclosures";
import attachmentsApi from "services/attachments";
import disbursementApi from "services/disbursements";
import reportApi from "services/report";
import organizationsApi from "services/organizations";
import leadsApi from "services/leads";

// ==============================|| REDUX - MAIN STORE ||============================== //

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(
      authApi.middleware,
      clientsApi.middleware,
      employeesApi.middleware,
      debtorsApi.middleware,
      applicationApi.middleware,
      dictionaryApi.middleware,
      signatureApi.middleware,
      contractsApi.middleware,
      enclosuresApi.middleware,
      attachmentsApi.middleware,
      disbursementApi.middleware,
      reportApi.middleware,
      organizationsApi.middleware,
      leadsApi.middleware
    ),
});

const persister = persistStore(store);

export type RootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

const { dispatch } = store;

const useDispatch = () => useAppDispatch<AppDispatch>();
const useSelector: TypedUseSelectorHook<RootState> = useAppSelector;

export { store, persister, dispatch, useSelector, useDispatch };
