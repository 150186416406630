export enum EApplicationController {
  create_application = "CREATE_APPLICATION", //Создание заявки
  update_application = "UPDATE_APPLICATION", // Изменение заявки
  fetch_application = "FETCH_APPLICATION", //Получение заявки
  delete_application = "DELETE_APPLICATION", //Удаление заявки(SoftDelete)
  reject_application = "REJECT_APPLICATION", // Отклонение заявки
  fetch_all_applications = "FETCH_ALL_APPLICATIONS", //Получение всех заявок
  fetch_application_for_current = "FETCH_APPLICATION_FOR_CURRENT", //Получение заявки клиента
  fetch_all_applications_for_current_user = "FETCH_ALL_APPLICATIONS_FOR_CURRENT_USER", //Получение всех заявок клиента
  fetch_application_status_history = "FETCH_APPLICATION_STATUS_HISTORY", //Получение истории статусов заявки
  upload_application_documents = "UPLOAD_APPLICATION_DOCUMENTS", //Загрузка документов для заявки
  delete_application_document = "DELETE_APPLICATION_DOCUMENT", //Удаление документа из заявки
  download_document = "DOWNLOAD_DOCUMENT", //Скачивание документа
}
export enum EDecisionController {
  create_sign_decision = "CREATE_SIGN_DECISION", //Создание рещения
  cm_sign_decision = "CM_SIGN_DECISION", //Подпись решения кредитным менеджером
  lawyer_sign_decision = "LAWYER_SIGN_DECISION", //Подпись решения юристом
  risk_manager_sign_decision = "RM_SIGN_DECISION", //Подпись решения риск менеджером
  revision_by_cm = "REVISION_BY_CM", //Доработка кредитным менеджером
}
export enum EDecisionAvailableActions {
  create_sign_decision = "CREATE_SIGN_DECISION", //Создание рещения
  cm_sign_decision = "CM_SIGN_DECISION", //Подпись решения кредитным менеджером
  lawyer_sign_decision = "LAWYER_SIGN_DECISION", //Подпись решения юристом
  risk_manager_sign_decision = "RM_SIGN_DECISION", //Подпись решения риск менеджером
  revision_by_cm = "REVISION_BY_CM", //Доработка кредитным менеджером
}
export enum EContractController {
  fetch_contract = "FETCH_CONTRACT", //Просмотр договора
  fetch_all_contracts = "FETCH_ALL_CONTRACTS", // Просмотр всех контрактов
  fetch_all_contracts_for_current_user = "FETCH_ALL_CONTRACTS_FOR_CURRENT_USER", // Просмотр всех контрактов в разрезе пользователя
  client_sign_contract = "CLIENT_SIGN_CONTRACT", //Подпись котракта клиентом
  director_sign_contract = "DIRECTOR_SIGN_CONTRACT", //Подпись котракта директором
  contract_revoke = "CONTRACT_REVOKE", //Аннулирование договора
}
export enum EContractAvailableActions {
  client_sign_contract = "CLIENT_SIGN_CONTRACT", //Подпись котракта клиентом
  director_sign_contract = "DIRECTOR_SIGN_CONTRACT", //Подпись котракта директором
  contract_revoke = "CONTRACT_REVOKE", //Аннулирование договора
}
export enum EEnclosureController {
  fetch_all_notice = "FETCH_ALL_NOTICE", //Получение всех уведомлений
  fetch_notice = "FETCH_NOTICE", //Получение уведомления
  fetch_all_notice_for_current_user = "FETCH_ALL_NOTICE_FOR_CURRENT_USER", //Получение всех уведомлений клиента'
  fetch_notice_for_current = "FETCH_NOTICE_FOR_CURRENT", //Получение уведомлений для текущего пользователя
  fetch_notice_status_history = "FETCH_NOTICE_STATUS_HISTORY", //Получение истории статусов уведомления
  fetch_notice_attachments = "FETCH_NOTICE_ATTACHMENTS", //Получение АВР/Накладных уведомления
  create_notice = "CREATE_NOTICE", //Создание уведомления
  create_notice_document = "CREATE_NOTICE_DOCUMENT", //Создание документа уведомления
  revision_notice = "REVISION_NOTICE", //Отправка уведомления на доработку

  fetch_all_registry = "FETCH_ALL_REGISTRY", //Получение всех реестров
  fetch_registry = "FETCH_REGISTRY", //Получение реестра
  fetch_all_registry_for_current_user = "FETCH_ALL_REGISTRY_FOR_CURRENT_USER", //Получение всех реестров клиента'
  fetch_registry_for_current = "FETCH_REGISTRY_FOR_CURRENT", //Получение реестров для текущего пользователя
  fetch_registry_status_history = "FETCH_REGISTRY_STATUS_HISTORY", //Получение истории статусов реестров
  fetch_registry_attachments = "FETCH_REGISTRY_ATTACHMENTS", //Получение АВР/Накладных реестров
  create_registry = "CREATE_REGISTRY", //Создание реестров
  create_registry_document = "CREATE_REGISTRY_DOCUMENT", //Создание документа реестров
  revision_registry = "REVISION_REGISTRY", //Отправка реестров на доработку
}
export enum EEnclosureAvailableActions {
  send_notice_to_sign = "SEND_NOTICE_TO_SIGN", //Отправить уведомления на подпись,
  client_sign_notice = "CLIENT_SIGN_NOTICE", //Подпись уведомления клиентом
  deputy_director_sign_notice = "DEPUTY_DIRECTOR_SIGN_NOTICE", //Подпись уведомления зам. диром.

  send_registry_to_sign = "SEND_REGISTRY_TO_SIGN", //Отправить акт приема-передачи документов на подпись,
  client_sign_registry = "CLIENT_SIGN_REGISTRY", //Подпись акт приема-передачи документов клиентом
  deputy_director_sign_registry = "DEPUTY_DIRECTOR_SIGN_REGISTRY", //Подпись акт приема-передачи документов зам. диром.

  revision_by_cm = "REVISION_BY_CM", //Доработка кредитным менеджером
}

export enum EDisbursementController {
  create_disbursement = "CREATE_DISBURSEMENT", //Создание выдачи
}
export enum EClientController {
  create_client = "CREATE_CLIENT", //Создание клиента
  update_client = "UPDATE_CLIENT", //Редактирование клиента
  fetch_client = "FETCH_CLIENT", //Редактирование клиента
  block_client = "BLOCK_CLIENT", //Удаление клиента
  fetch_all_clients = "FETCH_ALL_CLIENTS", //Получение всех клиентов
  fetch_role_list = "FETCH_ROLE_LIST", //Получение списка ролей
}

export enum EDictionaryController {
  fetch_application_status_list = "FETCH_APPLICATION_STATUS_LIST", // Получение статусов заявок
  fetch_day_category_list = "FETCH_DAY_CATEGORY_LIST", // Получение типов дней по отсрочке
  fetch_financing_limit_list = "FETCH_FINANCING_LIMIT_LIST", // Получение предельного размера финансирования
  fetch_factoring_type_list = "FETCH_FACTORING_TYPE_LIST", // Получение типов факторинга
  fetch_document_type_list = "FETCH_DOCUMENT_TYPE_LIST", // Получение типов документов
}

export enum EDocumentController {
  upload_document = "UPLOAD_DOCUMENT", //Загрузка документа
}

export enum EEmployeeController {
  create_employee = "CREATE_EMPLOYEE", // Создание сотрудника
  update_employee = "UPDATE_EMPLOYEE", // Обновление информации о сотруднике
  fetch_employee = "FETCH_EMPLOYEE", // Получение сотрудника
  block_employee = "BLOCK_EMPLOYEE", // Удаление сотрудника(Деактивация/Архивирование)
  fetch_all_employees = "FETCH_ALL_EMPLOYEES", // Получение всех сотрудников
  fetch_role_list = "FETCH_ROLE_LIST", // Получение списка ролей
}

export enum ESignatureController {
  sign_consent = "SIGN_CONSENT", // Подписание согласия
  load_consent = "LOAD_CONSENT", // Загрузка согласий
  assign_consent = "ASSIGN_CONSENT", // Отпрвить согласия на подписания
}

export const ActionList = {
  applications: EApplicationController,
  clients: EClientController,
  dictionaries: EDictionaryController,
  documents: EDocumentController,
  decisions: EDecisionController,
  employees: EEmployeeController,
  signatures: ESignatureController,
  contracts: EContractController,
  enclosures: EEnclosureController,
  disbursement: EDisbursementController,
};
export const AvailableActionList = {
  decisions: EDecisionAvailableActions,
  contracts: EContractAvailableActions,
  enclosures: EEnclosureAvailableActions,
};
export type AvailableActions =
  | EDecisionAvailableActions
  | EEnclosureAvailableActions;

export type Actions =
  | EApplicationController
  | EClientController
  | EDictionaryController
  | EDocumentController
  | EEmployeeController
  | ESignatureController
  | EDecisionController
  | EContractController
  | EEnclosureController
  | EDisbursementController;
