import { createApi } from "@reduxjs/toolkit/query/react";
import { baseAppQuery } from "./baseQuery";
import { ContactUs, ContactUsData, ContactUsFilter } from "types/contact-us";
import { formatDateUi } from "utils/converters";

const ENDPOINT = "/lead-application";
const leadsApi: any = createApi({
  reducerPath: "leadsApi",
  baseQuery: baseAppQuery,
  tagTypes: ["leads"],
  endpoints: (builder) => ({
    getLeadApplicationList: builder.query<ContactUsData[], ContactUsFilter>({
      query: (filter) => {
        const queryParams = new URLSearchParams();
        if (filter) {
          queryParams.set("page", filter.pageNumber.toString());
          queryParams.set("size", filter.pageSize.toString());
          if (filter.name) queryParams.set("name", filter.name);
          if (filter.mobile) queryParams.set("mobile", filter.mobile);
          if (filter.email) queryParams.set("email", filter.email);
          if (filter.status) queryParams.set("status", filter.status);
          if (filter.dateFrom) {
            queryParams.set("fromDate", formatDateUi(filter.dateFrom));
          }
          if (filter.dateTo) {
            queryParams.set("toDate", formatDateUi(filter.dateTo));
          }
        }
        return `${ENDPOINT}/fetch-all?${queryParams}`;
      },
      providesTags: ["leads"],
    }),
    getLeadApplicationById: builder.query<ContactUsData, string>({
      query: (id) => `${ENDPOINT}/${id}`,
      providesTags: ["leads"],
    }),
    createLeadApplication: builder.mutation<ContactUsData, ContactUs>({
      query: (data) => ({
        url: ENDPOINT,
        method: "POST",
        body: data,
      }),
    }),
    updateLeadApplication: builder.mutation<ContactUsData, ContactUs>({
      query: (data) => ({
        url: ENDPOINT,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["leads"],
    }),
  }),
});

export const {
  useGetLeadApplicationListQuery,
  useGetLeadApplicationByIdQuery,
  useCreateLeadApplicationMutation,
  useUpdateLeadApplicationMutation,
} = leadsApi;
export default leadsApi;
