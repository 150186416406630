import { useState, useEffect } from "react";

// material-ui
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";
import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";

//assets
import CookieIcon from "@mui/icons-material/Cookie";
import { Typography } from "@mui/material";

//third party
import { FormattedMessage } from "react-intl";

//utils
import { yandexMetrika } from "utils/analytics";

const CookieAlert = styled(Alert)(({ theme }) => ({
  position: "fixed",
  zIndex: 1500,
  padding: theme.spacing(2),
  width: "100%",
  bottom: 0,
  "& .MuiAlert-icon": {
    alignSelf: { xs: "flex-start", md: "center" },
  },
  "& .MuiAlert-message": {
    flex: 1,
  },
}));

const AllowButton = styled(Button)(({ theme }) => ({
  [theme.breakpoints.values.xs]: { width: "100%" },
}));
AllowButton.defaultProps = {
  ...AllowButton.defaultProps,
  size: "medium",
  variant: "outlined",
};

function setCookiePreferences(preferences: string) {
  document.cookie = `cookiePreferences=${preferences}; path=/; max-age=31536000`;
}

function getCookiePreferences() {
  const match = document.cookie.match(
    new RegExp("(^| )cookiePreferences=([^;]+)")
  );
  return match ? JSON.parse(match[2]) : null;
}

// ==============================|| ALLOW COOKIES ALERT ||============================== //

const AllowCookiesAlert = () => {
  const [showBanner, setShowBanner] = useState<boolean>(false);

  useEffect(() => {
    const preferences = getCookiePreferences();
    if (!preferences) setShowBanner(true);
    if (preferences) {
      setShowBanner(false);
      if (preferences.analytics) yandexMetrika();
    }
  }, [showBanner]);

  const handleAllowCookies = (allow: boolean) => {
    const preferences = { analytics: allow };
    setCookiePreferences(JSON.stringify(preferences));
    setShowBanner(false);
  };

  return (
    <>
      {showBanner && (
        <CookieAlert icon={false} severity="warning">
          <Stack
            spacing={2}
            direction={{ xs: "column", md: "row" }}
            justifyContent="space-between"
          >
            <Stack spacing={2} direction="row" alignItems="center">
              <CookieIcon fontSize="large" color="warning" />
              <Typography>
                <FormattedMessage
                  id="allowCookiesTitle"
                  values={{
                    policyLink: (
                      <Link href="/privacy-policy">
                        <FormattedMessage id="policyLink" />
                      </Link>
                    ),
                  }}
                />
              </Typography>
            </Stack>
            <Stack
              spacing={2}
              width={{ xs: "100%", md: "fit-content" }}
              direction={{ xs: "column", md: "row" }}
            >
              <AllowButton onClick={() => handleAllowCookies(true)}>
                <FormattedMessage id="allowCookiesAcceptButton" />
              </AllowButton>
              <AllowButton
                color="error"
                onClick={() => handleAllowCookies(false)}
              >
                <FormattedMessage id="allowCookiesDeclineButton" />
              </AllowButton>
            </Stack>
          </Stack>
        </CookieAlert>
      )}
    </>
  );
};

export default AllowCookiesAlert;
