// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports

import { dispatch } from "../index";

// types
import { DefaultRootStateProps } from "types";
import {
  KanbanColumn,
  KanbanComment,
  KanbanItem,
  KanbanUserStory,
} from "types/kanban";

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps["kanban"] = {
  error: null,
  columns: [],
  columnsOrder: [],
  comments: [],
  items: [],
  profiles: [],
  selectedItem: false,
  userStory: [],
  userStoryOrder: [],
};

const slice = createSlice({
  name: "kanban",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // ADD COLUMN
    addColumnSuccess(state, action) {
      state.columns = action.payload.columns;
      state.columnsOrder = action.payload.columnsOrder;
    },

    // EDIT COLUMN
    editColumnSuccess(state, action) {
      state.columns = action.payload.columns;
    },

    // UPDATE COLUMN ORDER
    updateColumnOrderSuccess(state, action) {
      state.columnsOrder = action.payload.columnsOrder;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      state.columns = action.payload.columns;
      state.columnsOrder = action.payload.columnsOrder;
    },

    // ADD ITEM
    addItemSuccess(state, action) {
      state.items = action.payload.items;
      state.columns = action.payload.columns;
      state.userStory = action.payload.userStory;
    },

    // EDIT ITEM
    editItemSuccess(state, action) {
      state.items = action.payload.items;
      state.columns = action.payload.columns;
      state.userStory = action.payload.userStory;
    },

    // UPDATE COLUMN ITEM ORDER
    updateColumnItemOrderSuccess(state, action) {
      state.columns = action.payload.columns;
    },

    // SELECT ITEM
    selectItemSuccess(state, action) {
      state.selectedItem = action.payload.selectedItem;
    },

    // ADD ITEM COMMENT
    addItemCommentSuccess(state, action) {
      state.items = action.payload.items;
      state.comments = action.payload.comments;
    },

    // DELETE ITEM
    deleteItemSuccess(state, action) {
      state.items = action.payload.items;
      state.columns = action.payload.columns;
      state.userStory = action.payload.userStory;
    },

    // ADD STORY
    addStorySuccess(state, action) {
      state.userStory = action.payload.userStory;
      state.userStoryOrder = action.payload.userStoryOrder;
    },

    // EDIT STORY
    editStorySuccess(state, action) {
      state.userStory = action.payload.userStory;
    },

    // UPDATE STORY ORDER
    updateStoryOrderSuccess(state, action) {
      state.userStoryOrder = action.payload.userStoryOrder;
    },

    // UPDATE STORY ITEM ORDER
    updateStoryItemOrderSuccess(state, action) {
      state.userStory = action.payload.userStory;
    },

    // ADD STORY COMMENT
    addStoryCommentSuccess(state, action) {
      state.userStory = action.payload.userStory;
      state.comments = action.payload.comments;
    },

    // DELETE STORY
    deleteStorySuccess(state, action) {
      state.userStory = action.payload.userStory;
      state.userStoryOrder = action.payload.userStoryOrder;
    },

    // GET COLUMNS
    getColumnsSuccess(state, action) {
      state.columns = action.payload;
    },

    // GET COLUMNS ORDER
    getColumnsOrderSuccess(state, action) {
      state.columnsOrder = action.payload;
    },

    // GET COMMENTS
    getCommentsSuccess(state, action) {
      state.comments = action.payload;
    },

    // GET PROFILES
    getProfilesSuccess(state, action) {
      state.profiles = action.payload;
    },

    // GET ITEMS
    getItemsSuccess(state, action) {
      state.items = action.payload;
    },

    // GET USER STORY
    getUserStorySuccess(state, action) {
      state.userStory = action.payload;
    },

    // GET USER STORY ORDER
    getUserStoryOrderSuccess(state, action) {
      state.userStoryOrder = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getColumns() {
  return async () => {
    try {
      dispatch(slice.actions.getColumnsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getColumnsOrder() {
  return async () => {
    try {
      dispatch(slice.actions.getColumnsOrderSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getComments() {
  return async () => {
    try {
      dispatch(slice.actions.getCommentsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProfiles() {
  return async () => {
    try {
      dispatch(slice.actions.getProfilesSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getItems() {
  return async () => {
    try {
      dispatch(slice.actions.getItemsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserStory() {
  return async () => {
    try {
      dispatch(slice.actions.getUserStorySuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getUserStoryOrder() {
  return async () => {
    try {
      dispatch(slice.actions.getUserStoryOrderSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addColumn(
  column: KanbanColumn,
  columns: KanbanColumn[],
  columnsOrder: string[]
) {
  return async () => {
    try {
      dispatch(slice.actions.addColumnSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editColumn(column: KanbanColumn, columns: KanbanColumn[]) {
  return async () => {
    try {
      dispatch(slice.actions.editColumnSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateColumnOrder(columnsOrder: string[]) {
  return async () => {
    try {
      dispatch(slice.actions.updateColumnOrderSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteColumn(
  columnId: string,
  columnsOrder: string[],
  columns: KanbanColumn[]
) {
  return async () => {
    try {
      dispatch(slice.actions.deleteColumnSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addItem(
  columnId: string,
  columns: KanbanColumn[],
  item: KanbanItem,
  items: KanbanItem[],
  storyId: string,
  userStory: KanbanUserStory[]
) {
  return async () => {
    try {
      dispatch(slice.actions.addItemSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editItem(
  columnId: string,
  columns: KanbanColumn[],
  item: KanbanItem,
  items: KanbanItem[],
  storyId: string,
  userStory: KanbanUserStory[]
) {
  return async () => {
    try {
      dispatch(slice.actions.editItemSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateColumnItemOrder(columns: KanbanColumn[]) {
  return async () => {
    try {
      dispatch(slice.actions.updateColumnItemOrderSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function selectItem(selectedItem: string | false) {
  return async () => {
    try {
      dispatch(slice.actions.selectItemSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addItemComment(
  itemId: string | false,
  comment: KanbanComment,
  items: KanbanItem[],
  comments: KanbanComment[]
) {
  return async () => {
    try {
      dispatch(slice.actions.addItemCommentSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteItem(
  itemId: string | false,
  items: KanbanItem[],
  columns: KanbanColumn[],
  userStory: KanbanUserStory[]
) {
  return async () => {
    try {
      dispatch(slice.actions.deleteItemSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addStory(
  story: any,
  userStory: KanbanUserStory[],
  userStoryOrder: string[]
) {
  return async () => {
    try {
      dispatch(slice.actions.addStorySuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editStory(
  story: KanbanUserStory,
  userStory: KanbanUserStory[]
) {
  return async () => {
    try {
      dispatch(slice.actions.editStorySuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateStoryOrder(userStoryOrder: string[]) {
  return async () => {
    try {
      dispatch(slice.actions.updateStoryOrderSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateStoryItemOrder(userStory: KanbanUserStory[]) {
  return async () => {
    try {
      dispatch(slice.actions.updateStoryItemOrderSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addStoryComment(
  storyId: string,
  comment: KanbanComment,
  comments: KanbanComment[],
  userStory: KanbanUserStory[]
) {
  return async () => {
    try {
      dispatch(slice.actions.addStoryCommentSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function deleteStory(
  storyId: string,
  userStory: KanbanUserStory[],
  userStoryOrder: string[]
) {
  return async () => {
    try {
      dispatch(slice.actions.deleteStorySuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
