import React, { Ref } from "react";

// material-ui
import Card, { CardProps } from "@mui/material/Card";
import CardContent, { CardContentProps } from "@mui/material/CardContent";
import CardHeader, { CardHeaderProps } from "@mui/material/CardHeader";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

// project-import
import useConfig from "hooks/useConfig";

// types
import { ThemeMode } from "types/config";
import { KeyedObject } from "types";

// constant
const headerSX = {
  "& .MuiCardHeader-action": { mr: 0 },
};

// ==============================|| CUSTOM MAIN CARD ||============================== //

export interface MainCardProps extends KeyedObject {
  border?: boolean;
  boxShadow?: boolean;
  children: React.ReactNode | string;
  style?: React.CSSProperties;
  content?: boolean;
  className?: string;
  contentClass?: string;
  contentSX?: CardContentProps["sx"];
  darkTitle?: boolean;
  sx?: CardProps["sx"];
  secondary?: CardHeaderProps["action"];
  shadow?: string;
  elevation?: number;
  title?: React.ReactNode | string;
  subheader?: React.ReactNode | string;
  headerDivider?: boolean;
}

const MainCard = React.forwardRef(
  (
    {
      border = false,
      boxShadow,
      children,
      content = true,
      contentClass = "",
      contentSX = {},
      darkTitle,
      secondary,
      shadow,
      sx = {},
      title,
      subheader,
      headerDivider = true,
      ...others
    }: MainCardProps,
    ref: Ref<HTMLDivElement>
  ) => {
    const { mode } = useConfig();
    const defaultShadow =
      mode === ThemeMode.DARK
        ? "0 2px 14px 0 rgb(33 150 243 / 10%)"
        : "0 2px 14px 0 rgb(32 40 45 / 8%)";

    return (
      <Card
        ref={ref}
        {...others}
        sx={{
          border: border ? "1px solid" : "none",
          borderColor: "divider",
          ":hover": {
            boxShadow: boxShadow ? shadow || defaultShadow : "inherit",
          },
          ...sx,
        }}>
        {/* card header and action */}
        {!darkTitle && title && (
          <CardHeader
            sx={headerSX}
            title={title}
            subheader={subheader}
            action={secondary}
          />
        )}
        {darkTitle && title && (
          <CardHeader
            sx={headerSX}
            title={<Typography variant="h3">{title}</Typography>}
            subheader={<Typography variant="subtitle1">{subheader}</Typography>}
            action={secondary}
          />
        )}

        {/* content & header divider */}
        {title && headerDivider && <Divider />}

        {/* card content */}
        {content && (
          <CardContent sx={contentSX} className={contentClass}>
            {children}
          </CardContent>
        )}
        {!content && children}
      </Card>
    );
  }
);

export default MainCard;
