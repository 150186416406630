import { lazy } from "react";
import { createBrowserRouter } from "react-router-dom";

// routes
import MainRoutes from "./MainRoutes";
import LoginRoutes from "./LoginRoutes";

// project import
import Loadable from "ui-component/Loadable";

//utils
import { getAvailableRoutes } from "routes/utils";

//types
import { Route } from "types";

const PagesLanding = Loadable(lazy(() => import("views/pages/landing")));
const PrivacyPolicy = Loadable(
  lazy(() => import("views/pages/landing/PrivacyPolicy"))
);

const createRouter = () =>
  createBrowserRouter(
    [
      { path: "/", element: <PagesLanding /> },
      {
        ...MainRoutes,
        children: getAvailableRoutes(MainRoutes?.children || []) as Route[],
      },
      LoginRoutes,
      { path: "/privacy-policy", element: <PrivacyPolicy /> },
    ],

    {
      basename: import.meta.env.VITE_APP_BASE_NAME,
    }
  );

export default createRouter;
