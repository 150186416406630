// third-party
import { createSlice } from "@reduxjs/toolkit";
import { FormikValues } from "formik";

// project imports

import { dispatch } from "../index";

// types
import { DefaultRootStateProps } from "types";

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps["calendar"] = {
  error: null,
  events: [],
};

const slice = createSlice({
  name: "calendar",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET EVENTS
    getEventsSuccess(state, action) {
      state.events = action.payload;
    },

    // ADD EVENT
    addEventSuccess(state, action) {
      state.events = action.payload;
    },

    // UPDATE EVENT
    updateEventSuccess(state, action) {
      state.events = action.payload;
    },

    // REMOVE EVENT
    removeEventSuccess(state, action) {
      state.events = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getEvents() {
  return async () => {
    try {
      dispatch(slice.actions.getEventsSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addEvent(event: FormikValues) {
  return async () => {
    try {
      dispatch(slice.actions.addEventSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function updateEvent(event: FormikValues) {
  return async () => {
    try {
      dispatch(slice.actions.updateEventSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function removeEvent(eventId: string) {
  return async () => {
    try {
      dispatch(slice.actions.removeEventSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
