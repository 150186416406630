//types
import { NavItemType, Route } from "types";

//third-party
import { ERoles } from "types/users";
import { Actions } from "types/actions";

//utils
import { getUserData } from "utils/getUserData";

//flat all paths in one array
export const flatAllPaths = (items: NavItemType[]) => {
  const paths: NavItemType[][] = items.map((item: NavItemType) => {
    if (item.children) {
      const formattedChildren = item.children.map((child) => ({
        ...child,
        parent: { id: item.id, url: item.url },
      }));
      return [item, flatAllPaths(formattedChildren)].flat();
    }
    return [item];
  });

  return paths.flat();
};

//get current path from url location
export const getCurrentPath = (paths: NavItemType[], pathname: string) => {
  const currentPath = paths.find((path) => {
    if (path?.url && path?.id) {
      if (path.url.includes("/:id")) {
        const keyWord = new RegExp(path.id);
        return keyWord.test(pathname);
      }
      return path.url === pathname;
    }
    return undefined;
  });
  return currentPath;
};

//get available routes
type AvailableRoute = NavItemType | Route;

const getAvailableRoute = (
  routes: AvailableRoute[],
  actions: Actions[]
): AvailableRoute[] => {
  const availableRoute: AvailableRoute[] = routes
    .map((route: AvailableRoute) => {
      if (!route?.actions) {
        if (route?.children) {
          return {
            ...route,
            children: getAvailableRoute(route.children, actions),
          };
        }
        return route;
      }

      const includeActions = route?.actions?.some((action: Actions) =>
        actions.includes(action)
      );

      if (!includeActions) return undefined!;

      if (route?.children) {
        return {
          ...route,
          children: getAvailableRoute(route.children, actions),
        };
      }
      return route;
    })
    .filter((route) => route !== undefined);

  return availableRoute;
};
export const getAvailableRoutes = (routes: AvailableRoute[]) => {
  const user = getUserData();
  if (user && user.actions) {
    const actions = user.actions.split(";") as Actions[];
    const availableRoutes = getAvailableRoute(routes, actions);

    return availableRoutes;
  }
};

// get initial page
export const getInitialPage = () => {
  const initialPages = {
    [ERoles.admin]: "/users/employees",
    [ERoles.director]: "/dashboard/applications",
    [ERoles.deputy_director]: "/dashboard/applications",
    [ERoles.credit_manager]: "/dashboard/applications",
    [ERoles.risk_manager]: "/dashboard/applications",
    [ERoles.lawyer]: "/dashboard/applications",
    [ERoles.client]: "/dashboard/applications",
  };
  const user = getUserData();
  if (!user) return "";

  const roles = user.role.split(";") as ERoles[];
  return initialPages[roles[0]];
};
