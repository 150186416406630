import { createApi } from "@reduxjs/toolkit/query/react";
import { baseAuthQuery } from "./baseQuery";
import { Token } from "types/token";

const ENDPOINT = "auth";
const authApi = createApi({
  reducerPath: "authApi",
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  baseQuery: baseAuthQuery,
  endpoints: (builder) => ({
    login: builder.mutation<Token, string>({
      query: (xml: string) => ({
        url: `${ENDPOINT}/sign-in`,
        method: "POST",
        body: xml,
        headers: {
          "Content-Type": "application/xml;charset=UTF-8",
        },
      }),
    }),
  }),
});

export const { useLoginMutation } = authApi;
export default authApi;
