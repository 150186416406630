//material ui
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
//project imports
import { useGetSignatureListCurrentQuery } from "services/signatures";

//types
import { NavItemType } from "types";
// ==============================|| MENU ITEM CHIP ||============================== //
const MenuItemChip = ({ item }: { item: NavItemType }) => {
  const { data: signature } = useGetSignatureListCurrentQuery(
    {
      pageNumber: 0,
      pageSize: 0,
    },
    { skip: item.id !== "signatures" }
  );

  if (!signature?.totalElements || !item.chip) return null;
  const label = item.chip.label || signature?.totalElements;
  return (
    <Chip
      color={item.chip.color}
      variant={item.chip.variant}
      size={item.chip.size}
      label={label}
      avatar={item.chip.avatar && <Avatar>{item.chip.avatar}</Avatar>}
    />
  );
};

export default MenuItemChip;
