// project imports
import Locales from "ui-component/Locales";
import NavigationScroll from "layout/NavigationScroll";
import RTLLayout from "ui-component/RTLLayout";
import Snackbar from "ui-component/extended/Snackbar";
import Notistack from "ui-component/third-party/Notistack";
import AllowCookiesAlert from "ui-component/AllowCookiesAlert";
import ThemeCustomization from "themes";
import Router from "./Router";

// ==============================|| APP ||============================== //

const App = () => (
  <ThemeCustomization>
    <RTLLayout>
      <Locales>
        <NavigationScroll>
          <>
            <Notistack>
              <Router />
              <Snackbar />
              <AllowCookiesAlert />
            </Notistack>
          </>
        </NavigationScroll>
      </Locales>
    </RTLLayout>
  </ThemeCustomization>
);

export default App;
