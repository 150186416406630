import { useMemo } from "react";
import { useLocation, useParams } from "react-router-dom";
import navigation from "menu-items";
import useGetApplicationById from "./useGetApplicationById";
import { useIntl } from "react-intl";
import { flatAllPaths, getCurrentPath, getInitialPage } from "routes/utils";

// ==============================|| GET BREADCRUMBS - HOOKS ||============================== //

const useGetBreadcrumbs = () => {
  const intl = useIntl();
  const { pathname } = useLocation();
  const { id, applicationId, ordinalNumber } = useParams();
  const appId = pathname.includes("application") ? id : applicationId;
  const { application } = useGetApplicationById(appId);

  const currentBreadcrumb = useMemo(() => {
    const allPaths = flatAllPaths(navigation.items);
    const currentPath = getCurrentPath(allPaths, pathname);

    const breadcrumbLinks = [
      {
        title: intl.formatMessage({ id: "main" }),
        to: getInitialPage(),
      },
      {
        title: intl.formatMessage({ id: currentPath?.parent?.id }),
        to: currentPath?.parent?.url,
      },
      {
        title: intl.formatMessage({ id: currentPath?.id }),
      },
    ];

    if (appId && application?.organization) {
      const { id, orgName } = application.organization;
      breadcrumbLinks.unshift({
        title: orgName,
        to: `users/organizations/organization-details/${id}`,
      });
    }
    let breadcrumbHeading = intl.formatMessage({ id: currentPath?.id });
    if (ordinalNumber) {
      breadcrumbHeading += `: #${ordinalNumber}`;
    }

    const breadcrumb = {
      breadcrumbHeading,
      breadcrumbLinks,
    };

    return breadcrumb;
  }, [application, pathname, intl, appId, ordinalNumber]);

  return currentBreadcrumb;
};
export default useGetBreadcrumbs;
