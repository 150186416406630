import { Link as RouterLink } from "react-router-dom";

// material-ui
import Link from "@mui/material/Link";

// project imports
import Logo from "ui-component/svg-images/Logo";
//utils
import { getInitialPage } from "routes/utils";

// ==============================|| MAIN LOGO ||============================== //

const LogoSection = () => (
  <Link component={RouterLink} to={getInitialPage()} aria-label="theme-logo">
    <Logo />
  </Link>
);

export default LogoSection;
