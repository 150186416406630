import { createApi } from "@reduxjs/toolkit/query/react";
import { baseAppQuery } from "./baseQuery";
import { AttachmentInfo } from "types/contracts";

const ENDPOINT = "attachment";
const attachmentsApi: any = createApi({
  reducerPath: "attachmentsApi",
  baseQuery: baseAppQuery,
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  tagTypes: ["attachments"],
  endpoints: (builder) => ({
    uploadAttachmentDocument: builder.mutation<
      AttachmentInfo,
      { attachmentId: string; file: File }
    >({
      query: ({ attachmentId, file }) => {
        let formData = new FormData();
        formData.append("files", file);
        return {
          url: `${ENDPOINT}/document/upload?attachmentId=${attachmentId}`,
          method: "POST",
          body: formData,
        };
      },
    }),
    deleteAttachmentDocument: builder.mutation<
      File,
      { attachmentId: string; documentId: string }
    >({
      query: ({ attachmentId, documentId }) => ({
        url: `${ENDPOINT}/document/delete?attachmentId=${attachmentId}&documentId=${documentId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useUploadAttachmentDocumentMutation,
  useDeleteAttachmentDocumentMutation,
} = attachmentsApi;
export default attachmentsApi;
