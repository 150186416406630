import { createApi } from "@reduxjs/toolkit/query/react";
import { baseAppQuery } from "./baseQuery";
import { Dictionary } from "types/dictionary";

const ENDPOINT = "dictionary";
const dictionariesApi: any = createApi({
  reducerPath: "dictionarysApi",
  baseQuery: baseAppQuery,
  keepUnusedDataFor: 0,
  refetchOnMountOrArgChange: true,
  tagTypes: ["dictionarys"],
  endpoints: (builder) => ({
    getApplicationStatusList: builder.query<Dictionary, Dictionary>({
      query: () => `${ENDPOINT}/application-status/list`,
    }),
    getDayCategoryList: builder.query<Dictionary, Dictionary>({
      query: () => `${ENDPOINT}/day-category/list`,
    }),
    getDocumentTypeList: builder.query<Dictionary, Dictionary>({
      query: () => `${ENDPOINT}/document-type/list`,
    }),
    getFactoringTypeList: builder.query<Dictionary, Dictionary>({
      query: () => `${ENDPOINT}/factoring-type/list`,
    }),
  }),
});

export const {
  useGetApplicationStatusListQuery,
  useGetDayCategoryListQuery,
  useGetDocumentTypeListQuery,
  useGetFactoringTypeListQuery,
} = dictionariesApi;
export default dictionariesApi;
