import { useNavigate } from "react-router-dom";

// project imports
import { GuardProps } from "types";
import { useEffect } from "react";

//assets
import useAuth from "hooks/useAuth";

// ==============================|| AUTH GUARD ||============================== //

/**
 * Authentication guard for routes
 * @param {PropTypes.node} children children element/node
 */
const AuthGuard = ({ children }: GuardProps) => {
  const { loggedIn, expired } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!loggedIn || expired) {
      if (expired) {
        localStorage.clear();
        window.dispatchEvent(new Event("storage"));
      }

      navigate("login", { replace: true });
    }
  }, [loggedIn, expired, navigate]);

  return children;
};

export default AuthGuard;
