// third-party
import { createSlice } from "@reduxjs/toolkit";

// project imports

import { dispatch } from "../index";

// types
import { DefaultRootStateProps } from "types";
import { Address } from "types/e-commerce";

// ----------------------------------------------------------------------

const initialState: DefaultRootStateProps["product"] = {
  error: null,
  addresses: [],
};

const slice = createSlice({
  name: "product",
  initialState,
  reducers: {
    // HAS ERROR
    hasError(state, action) {
      state.error = action.payload;
    },

    // GET ADDRESSES
    getAddressesSuccess(state, action) {
      state.addresses = action.payload;
    },

    // ADD ADDRESS
    addAddressSuccess(state, action) {
      state.addresses = action.payload;
    },

    // EDIT ADDRESS
    editAddressSuccess(state, action) {
      state.addresses = action.payload;
    },
  },
});

// Reducer
export default slice.reducer;

// ⬇️ this is the redux functions

export function getAddresses() {
  return async () => {
    try {
      dispatch(slice.actions.getAddressesSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function addAddress(address: Address) {
  return async () => {
    try {
      dispatch(slice.actions.addAddressSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function editAddress(address: Address) {
  return async () => {
    try {
      dispatch(slice.actions.editAddressSuccess([]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
