import useRestrictedFeature from "hooks/useRestrictedFeature";
import {
  useGetApplicationByIdQuery,
  useGetApplicationByIdCurrentQuery,
} from "services/applications";

import { EApplicationController } from "types/actions";
import { ApplicationInfo } from "types/application";

// ==============================|| GET APPLICATION BY ID - HOOKS ||============================== //

const useGetApplicationById = (applicationId: string | undefined) => {
  const isClient = useRestrictedFeature([
    EApplicationController.fetch_application_for_current,
  ]);
  const isEmployee = useRestrictedFeature([
    EApplicationController.fetch_all_applications,
  ]);

  const { data: employee, refetch: refetchEmployee } =
    useGetApplicationByIdQuery(applicationId, {
      skip: !isEmployee || !applicationId,
    });

  const { data: client, refetch: refetchClient } =
    useGetApplicationByIdCurrentQuery(applicationId, {
      skip: !isClient || !applicationId,
    });

  const application: ApplicationInfo = employee || client;
  const refetchApplication = isEmployee ? refetchEmployee : refetchClient;

  return {
    application,
    refetchApplication,
  };
};
export default useGetApplicationById;
