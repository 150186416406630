import { fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { BaseQueryApi } from "@reduxjs/toolkit/query";

export const BASE_APP_URL =
  window.location.hostname === import.meta.env.VITE_APP_FINQ_HOSTNAME
    ? `/api/`
    : `${import.meta.env.VITE_APP_FINQ_URL}/api/`;

function injectToken(
  headers: Headers,
  api: Pick<BaseQueryApi, "getState" | "extra" | "endpoint" | "type" | "forced">
): Headers {
  const token = localStorage.getItem("accessToken");
  if (!token) return headers;
  headers.set("Authorization", `Bearer ${token}`);
  return headers;
}

export const baseAppQuery = fetchBaseQuery({
  baseUrl: BASE_APP_URL,
  prepareHeaders: (headers, api) => {
    headers = injectToken(headers, api);
    headers.set("Accept-Language", "ru");
    return headers;
  },
});

export const baseAuthQuery = fetchBaseQuery({
  baseUrl: BASE_APP_URL,
});
