// material-ui
import Typography from "@mui/material/Typography";
import { AvatarProps } from "@mui/material/Avatar";
// project imports
import Avatar from "ui-component/extended/Avatar";
import useConfig from "hooks/useConfig";

// types
import { ThemeMode } from "types/config";

// third-party
import { useIntl } from "react-intl";

//utils
import { userFullName } from "utils/converters";

type FullName = {
  firstName: string;
  lastName: string;
  middleName: string | undefined;
};
type LocalizedAvatarProps = {
  avatar: string;
  fullName: FullName;
  size?: "badge" | "xs" | "sm" | "md" | "lg" | "xl";
  sx?: AvatarProps["sx"];
};
// ==============================|| Localized Avatar ||============================== //
const LocalizedAvatar = ({
  avatar,
  fullName,
  size,
  sx,
}: LocalizedAvatarProps) => {
  const intl = useIntl();
  const { mode } = useConfig();
  const { firstName, lastName, middleName } = fullName;
  const fullInitials = userFullName(firstName, lastName, middleName, true);
  const alt = fullInitials || intl.formatMessage({ id: "notFilledIn" });
  return (
    <Avatar alt={alt} sx={{ fontSize: 14, ...sx }} size={size}>
      <Typography
        sx={{
          color: mode === ThemeMode.DARK ? "primary.dark" : "primary.light",
          lineHeight: "40px",
        }}>
        {avatar}
      </Typography>
    </Avatar>
  );
};
export default LocalizedAvatar;
