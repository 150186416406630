import { useCallback, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";

// project-imports
import useConfig from "hooks/useConfig";

// assets
import { IconMoon, IconSun } from "@tabler/icons-react";

// types
import { ThemeMode } from "types/config";

// ==============================|| HEADER CONTENT - THEME||============================== //

const ThemeSection = () => {
  const theme = useTheme();
  const { mode, onChangeMode } = useConfig();

  const handleToggle = useCallback(() => {
    onChangeMode(mode === ThemeMode.DARK ? ThemeMode.LIGHT : ThemeMode.DARK);
  }, [mode]);

  return (
    <Box sx={{ ml: { xs: 0, sm: 2 } }}>
      <Avatar
        variant="rounded"
        sx={{
          ...theme.typography.commonAvatar,
          ...theme.typography.mediumAvatar,
          bgcolor: mode === ThemeMode.DARK ? "dark.main" : "secondary.light",
          color: "secondary.main",
          transition: "all .2s ease-in-out",
          '&[aria-controls="menu-list-grow"],&:hover': {
            bgcolor:
              theme.palette.mode === ThemeMode.DARK
                ? "secondary.main"
                : "secondary.dark",
            color:
              theme.palette.mode === ThemeMode.DARK
                ? "grey.800"
                : "secondary.light",
          },
        }}
        aria-haspopup="true"
        onClick={handleToggle}
        color="inherit">
        {mode === ThemeMode.DARK ? <IconMoon /> : <IconSun />}
      </Avatar>
    </Box>
  );
};

export default ThemeSection;
