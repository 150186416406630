import useTokenData from "./useTokenData";

// ==============================|| AUTH - HOOKS ||============================== //
const useAuth = () => {
  const token = useTokenData();
  let currentDate = new Date();
  let expired = token && token?.exp * 1000 < currentDate.getTime();

  return { loggedIn: Boolean(token), expired };
};
export default useAuth;
