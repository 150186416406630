import useTokenData from "./useTokenData";
import { Actions } from "types/actions";

// ==============================|| RESTRICTED FEATURES - HOOKS ||============================== //
const useRestrictedFeature = (featureList: Actions[]) => {
  const token = useTokenData();
  if (!token) return true;
  const actionList = token.actions.split(";") as Actions[];
  const hasFeature = featureList.some((feature) =>
    actionList.includes(feature)
  );
  return hasFeature;
};
export default useRestrictedFeature;
